.FormLabel.tags {
  display: flex;
  .tag {
    margin-bottom: 0;
  }
   > .tag:first-child {
    background-color: transparent;
     font-size: 1rem;
     margin: 0;
     padding-left: 0;
  }
}