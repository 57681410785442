.Loading {
  display: none;
}
.Loading.is-active {
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  > div > div {
    position: absolute;
    border: 4px solid $link;
    opacity: 1;
    border-radius: 50%;
    animation: Loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  > div > div:nth-child(2) {
    animation-delay: -0.5s;
  }
}

@keyframes Loading {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
