.file-cta.is-loading {
  .file-icon {
    color: transparent !important;
    pointer-events: none;
    &::after {
      position: absolute;
      top: calc(50% - (1em / 2));
      position: absolute !important;
      animation: spinAround 500ms infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
    }
  }
}